import axios from "@/axios.js";

export default {
  async fetchBrandTestimonies({ commit }) {
    try {
      const { data } = await axios.get(`/brand-testimony`);
      commit("setTestimonies", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchTestimony({ commit }, params) {
    try {
      const { data } = await axios.get(`/brand-testimony/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeTestimony({ commit }, params) {
    try {
      const { data } = await axios.post(`/brand-testimony`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateTestimony({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/brand-testimony/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeTestimony({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/brand-testimony/${params}`);
      dispatch("fetchBrandTestimonies");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
